// Action-areas.less
// -----------------

// Page header actions
.header-actions {
  line-height: 36px;
  padding: 0 20px;
}

// Table actions
.table-actions {
  .clearfix();
  padding-bottom: 20px;
}
