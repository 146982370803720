// Page-alert.less
// An alert at the top of the page
// -------------------------------

.page-alert {
  position: absolute;
  width: 0;
  top: 0;
  left: 50%;
  z-index: 1020;
  .alert {
    width: 550px;
    margin-left: -300px;  // incl half of 49px horizontal padding
    border-top-width: 0;
    .border-radius(0 0 4px 4px);
  }
}
.navbar-fixed-top + .page-alert {
  top: 40px;
}
body > .page-alert {
  position: fixed;
}
