//========================================================================
// 3rd party imports
//========================================================================

@import "bootstrap.less";
@import "responsive.less";
@import "jasny-bootstrap.less";
@import (less) "font-awesome.css";

//========================================================================
// Empirix imports
//========================================================================

@import "empirix-variables.less";
@import "empirix-bootstrap-ext.less";

//========================================================================
// Font for Empirix Logo etc.
//========================================================================

@font-face {
  font-family: 'empirix';
  src:url('../font/empirix.eot?-yar1nw');
  src:url('../font/empirix.eot?#iefix-yar1nw') format('embedded-opentype'),
    url('../font/empirix.woff?-yar1nw') format('woff'),
    url('../font/empirix.ttf?-yar1nw') format('truetype'),
    url('../font/empirix.svg?-yar1nw#empirix') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="empx-"], [class*=" empx-"] {
  font-family: 'empirix';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.empx-logo:before {
  content: "\e600";
}

//========================================================================
//  Top Navigation Bar for all "portal" applications
//========================================================================

#empirix-portal-navbar {

  @BackgroundColor:   #1b1b1b; 
  @MuteColor:         #b2b5b4;
  @TextColor:         white;
  @LogoColor:         @EmpirixGreen;

  position:   relative;
  top:        0px;
  left:       0px;
  right:      0px;

  .navbar-inner {
    min-height:         50px;
    padding-left:       5px;
    padding-right:      0px;
    color:              @MuteColor;
    background-color:   @BackgroundColor;
  }
    
  li.button.selected:not(.dropdown) a,
  li.submenu.selected a {
    color: #9dcb63;
  }
  
  .dropdown {
    a {
      min-width: 120px;
      padding-top: 15px;      
      padding-bottom: 15px;
      padding-left: 20px;
      box-sizing: border-box;

      b {
        margin-left: 5px;
        float: right;
      }

    }
  }

  .dropdown-menu {
    min-width: 120px;
    background-color: @BackgroundColor;
    border-radius: 0px;
    margin-top: 0px;

    li {
      a {
        color: @TextColor;
        cursor: pointer;
        padding: 3px 15px 3px 15px;
        padding-left: 20px;
      }

      a.active {
        padding-left: 20px-3-3;
      }
      a.active:before{
        color: @EmpirixGreen;
        content: '';
        border-left: solid 3px #888;
        padding-right: 3px;
      }
      
      span {
        color: @MuteColor;
        cursor: default;
        padding: 3px 15px 3px 15px;
        padding-left: 20px;
        font-size: 11.5px;
      }
    }
    .divider {
      background-color: @MuteColor;
      border-bottom: 1px solid @MuteColor;
    }

  }

  .secondary a {
    color: #616363;
  }
    
  .logo  {

      > a {
        padding: 0px;
        
        
	      > img {
	      	height: 50px;
	      }
      }

      i {

        display:        inline-block;
        vertical-align: middle;
        padding-top:    6px;
        padding-left:   5px;

        font-size:      25px;
        color:          @LogoColor;
      }

      span {

        display:        inline-block;
        vertical-align: middle;
        padding-top:    10px;
        padding-right:  20px;

        font-weight:    bold;
        letter-spacing: 2px;
        color:          white;

      }
      
  }
  
  .nav > li > .dropdown-menu:after {
    content: none;
  }

  .product {

    position: relative; 
    margin-left: 0px;

    > a{
      cursor: auto;
      padding-left: 0px;      
      color: #FFFFFF;
      
      > span {
        letter-spacing: 1px;
        padding-right:  10px;
        padding-left:   20px;
         
      }
    }

    a.dropdown-toggle {
      cursor: pointer;
      color: #FFFFFF;
    }

  }

  .userinfo {
    min-width:      100px;
    padding-right:  5px;

    > a {
        letter-spacing: 1px;  
        color: #FFFFFF;    
    }
  }

}

//========================================================================
// Re-Login element to be used to display a relogin IFAME
// on top of regular application contents.
//========================================================================

#empirix-portal-relogin {
  position: fixed;
  top: 0px;
  left:  0px;
  border:  none;
  width:  100%;
  height:  100%;
  background-color: white;
  z-index: 10000;
}


//========================================================================
// Portal Dialogs
//
// These classes is used for simple portal dialogs, such as login,
// logout, password change etc.
//========================================================================

.portal-dialog {

  background-color: #f0f0f0;
  border:           1px solid #e2e4e3;
  border-radius:    15px;
  box-sizing:       border-box;
  padding:          15px;

  //---------------------------------------------------------------------------
  // An optional message above dialog. May be used for error reporting. 
  //---------------------------------------------------------------------------
  h4 {
    position: absolute;
    top: -2em;
    color: #ccc;
  }

  .line {
    margin: 15px 0px 5px 0px;
    color: black;
    font-weight: bold;
  }

  .input-div input {
    border-radius: 0px;
    border: 0px;
    width: 98%;
    line-height: 2em;
    height: 30px;
  }


  //---------------------------------------------------------------------------
  // Button panel at the bottom of the dialog
  //---------------------------------------------------------------------------
  .buttons {
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 60px;

    //-------------------------------------------------------------------------
    // Primary dialog button
    //-------------------------------------------------------------------------
    .primary-button {
      float: right;
      min-width: 100px;
      padding: 0px 1em;
      background-color: @EmpirixGreen;
      color: white;
      border: 0px;
      border-radius: 5px;
      height: 50px;
    }

    //-------------------------------------------------------------------------
    // Secondary dialog button
    //-------------------------------------------------------------------------
    .cancel-button {
      float: right;
      padding: 0px 2em;
      color: @EmpirixGreen;
      height: 50px;
      line-height: 50px;
      font-weight: bold;
    }    
  }
}

.portal-dialog-position(@height) {
  @width:           @height * 1.61803398875;
  position:         absolute;

  width:            @width;
  //height:         @height;

  top:              50%;
  left:             50%;

  margin:           -@height/2 0px 0px -@width/2;
}


.portal-dialog {
  .portal-dialog-position(300px);
}

.portal-dialog.portal-dialog-large {
  .portal-dialog-position(400px);  
}



.application-menu {

  position: fixed;
  top: 51px;
  left: 0px;   
  width: 200px;
  bottom: 0px;
  overflow: hidden;
  background-color: #eee;

  ul {
    margin: 20px 0px 0px 0px;
  }

  li {
    display: block;

    a {
      display: block;
      color: #666;
      text-decoration: none;
      padding: 5px 20px 5px 20px;
      //border-top: 1px solid #bbb; 
      border-bottom: 1px solid #ddd; 
      font-weight: bold;
    }

    a.active {
      background-color: white;
      color: #333;
      //border-top: 1px solid white; 
      border-bottom: 1px solid white; 
    }

  }
}

//========================================================================
// Application layout classes
//========================================================================

.application-with-menu,
.application {

  position: fixed;
  top: 84px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding: 20px;
}

.application {
  left: 0px;   
}

.application-with-menu {
  left: 200px;   
}

//========================================================================
// Report list classes
//========================================================================

ul.nav {
	border-top: none;
}

ul.nav label {
	border-top: solid 1px #EEEEEE;
	padding-top: 5px;
	margin-top: 0px;
	margin-bottom: 0px;
	color: #000000;
	font-size: 15px;
	text-transform: none;
	cursor: default;
}

ul.nav label.nav-header > span {
	cursor: pointer;
}

li.license-false {
	display: none;
}

label.nav-header > span.expand-all {
	font-weight: normal;
	font-size: 12px;
	font-color: #666666;
}

.header-reports {
	h3 {
		color: #7fba00;
	} 
} 

.search-results { 

	.results-header {
		font-weight: normal;
		border-bottom: solid 1px #CCCCCC; 
	}
	
	span.path, span.path a {
		color: #999999;
	}
	
	li {
		margin-top: 8px;
	}
	
	.icon {
		margin-right: 6px;
	}
	
	a { 
		cursor: pointer; 
	}
} 

#top-link-block.affix-top {
    position: absolute; /* allows it to "slide" up into view */
    bottom: -82px;
    left: 10px;
}
#top-link-block.affix {
    position: fixed; /* keeps it on the bottom once in view */
    bottom: 18px;
    left: 10px;
}


body {

	ul.cluster-nodes {
		margin-left: 0px;
	}

	.aggregate.badge {
		display: inline-block;
  		position: relative;
  		top: -2px;
	}

	.badge { 
		margin-left: 4px;
		position: relative;
		top: -2px;
		font-weight: normal;
		font-size: 9px;
		text-shadow: none;
	}

	.badge.green {
		background-color: #468847;
	}
	
	.badge.yellow {
		background-color: #f89406;
	}
	
	.badge.red {
		background-color: #b94a48;
	}

	#btnClusterStatus {
		color: #AAAAAA;
		margin: 10px 20px 0 0 ; 
		padding: 4px 12px;
		border: 0;
		visibility: hidden;
	}
	
	#btnClusterStatus:hover, #btnClusterStatus:active {
		color: #FFFFFF; 
	}
	
	#btnClusterStatus:focus {
  		outline: none;
  	}
  	
  	a[disabled] { pointer-events: none; } 
	
	.cluster-nodes {
		list-style: none;
		margin-top: 6px;
		
		> li { 
			margin-top: 6px;
		}
	}
} 

.modal {

	.modal-header {
		
		h3 {
		
			font-size: 18px;
		}
	}
}
 
/* These "idmc" styles are Used on the Home page */

#idmc {
  background: white;
  position: fixed;
  overflow: hidden;
  left: 0px;
  right: 0px;
  top: 50px;
  bottom: 0px;
  font-family: 'Lucida Grande',Arial,sans-serif;
}

#idmc-nav {
    
    font-size: 12px;
    line-height: 18px;   
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 248px;
    padding: 0px 10px 100px 10px;
    border-right: solid 1px #ddd;
    overflow-x: hidden;
    overflow-y: auto;
}

#idmc-nav .header {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.5em; 
  margin-bottom: 0.5em; 
  margin-top: 1em; 
  padding-bottom: 0px; 
}

#idmc-nav ul {
    list-style: none;
}

#idmc-nav li{
    padding-left: 2px;
    line-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

#idmc-nav li a{
    display: block;
}

#idmc-nav li a{
    text-decoration: none;
    white-space:  nowrap;
    overflow:     hidden;
    text-overflow:  ellipsis;
    cursor:     pointer;  
}    

#idmc-nav a.is-selected{ 
    background-color:  #7FBA00;
    color: white;
}

#idmc-nav .is-selected a{ 
    background-color:  #7FBA00;
    color: white;
}

/*
  +--------------------------------------------------------------------------+
  |                 IDMC Page Content                                        |
  +--------------------------------------------------------------------------+
 */

 
#idmc-content {
    
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 270px;
    
    padding: 18px 10px 18px 10px;
    
    font-family: 'Lucida Grande',Arial,sans-serif;
    font-size: 12px;
    line-height: 18px;      
    
    overflow-x: hidden;
    overflow-y: auto;
}
