// Forms-uneditable.less
// CSS for uneditable forms and form elements
// ------------------------------------------

.uneditable-input,
.uneditable-textarea {
  display: inline-block;
  font-size: @baseFontSize;
  line-height: @baseLineHeight;
  color: @gray;
  cursor: not-allowed;
  background-color: @inputBackground;
  .box-shadow(inset 0 1px 2px rgba(0,0,0,.025));
  padding: 4px 3px 4px 5px;
  border: 1px solid #eee;
  .border-radius(@inputBorderRadius);
  .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
}
.uneditable-input {
  white-space: pre;
  overflow: hidden;  // prevent text from wrapping, but still cut it off like an input does
  height: @baseLineHeight;
}
.uneditable-textarea {
  white-space: pre-wrap;
  overflow-y: auto;
  overflow-x: hidden;
}


// DISABLED STATE
// --------------

// Disabled and read-only inputs
select[disabled],
textarea[disabled],
input[type="text"][disabled],
input[type="password"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="date"][disabled],
input[type="month"][disabled],
input[type="time"][disabled],
input[type="week"][disabled],
input[type="number"][disabled],
input[type="email"][disabled],
input[type="url"][disabled],
input[type="search"][disabled]
{
  color: #999;
}
.uneditable-input.disabled,
.uneditable-textarea.disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  cursor: not-allowed;
  color: #999;
}


// TEXTAREA
// --------

// Make sure textarea has the same size in all browsers
textarea,
.uneditable-textarea {
  height: 3 * @baseLineHeight;
  &[rows="1"]   { height:  (1 +  1) * @baseLineHeight; }
  &[rows="2"]   { height:  (1 +  2) * @baseLineHeight; }
  &[rows="3"]   { height:  (1 +  3) * @baseLineHeight; }
  &[rows="4"]   { height:  (1 +  4) * @baseLineHeight; }
  &[rows="5"]   { height:  (1 +  5) * @baseLineHeight; }
  &[rows="6"]   { height:  (1 +  6) * @baseLineHeight; }
  &[rows="7"]   { height:  (1 +  7) * @baseLineHeight; }
  &[rows="8"]   { height:  (1 +  8) * @baseLineHeight; }
  &[rows="9"]   { height:  (1 +  9) * @baseLineHeight; }
  &[rows="10"]  { height:  (1 + 10) * @baseLineHeight; }
  &[rows="11"]  { height:  (1 + 11) * @baseLineHeight; }
  &[rows="12"]  { height:  (1 + 12) * @baseLineHeight; }
  &[rows="13"]  { height:  (1 + 13) * @baseLineHeight; }
  &[rows="14"]  { height:  (1 + 14) * @baseLineHeight; }
  &[rows="15"]  { height:  (1 + 15) * @baseLineHeight; }
  &[rows="16"]  { height:  (1 + 16) * @baseLineHeight; }
  &[rows="17"]  { height:  (1 + 17) * @baseLineHeight; }
  &[rows="18"]  { height:  (1 + 18) * @baseLineHeight; }
  &[rows="19"]  { height:  (1 + 19) * @baseLineHeight; }
  &[rows="20"]  { height:  (1 + 20) * @baseLineHeight; }
  &[rows="21"]  { height:  (1 + 21) * @baseLineHeight; }
  &[rows="22"]  { height:  (1 + 22) * @baseLineHeight; }
  &[rows="23"]  { height:  (1 + 23) * @baseLineHeight; }
  &[rows="24"]  { height:  (1 + 24) * @baseLineHeight; }
  &[rows="25"]  { height:  (1 + 25) * @baseLineHeight; }
  &[rows="26"]  { height:  (1 + 26) * @baseLineHeight; }
  &[rows="27"]  { height:  (1 + 27) * @baseLineHeight; }
  &[rows="28"]  { height:  (1 + 28) * @baseLineHeight; }
  &[rows="29"]  { height:  (1 + 29) * @baseLineHeight; }
  &[rows="30"]  { height:  (1 + 30) * @baseLineHeight; }
  &[rows="35"]  { height:  (1 + 35) * @baseLineHeight; }
  &[rows="40"]  { height:  (1 + 40) * @baseLineHeight; }
  &[rows="45"]  { height:  (1 + 45) * @baseLineHeight; }
  &[rows="50"]  { height:  (1 + 50) * @baseLineHeight; }
  &[rows="55"]  { height:  (1 + 55) * @baseLineHeight; }
  &[rows="60"]  { height:  (1 + 60) * @baseLineHeight; }
  &[rows="65"]  { height:  (1 + 65) * @baseLineHeight; }
  &[rows="70"]  { height:  (1 + 70) * @baseLineHeight; }
  &[rows="75"]  { height:  (1 + 75) * @baseLineHeight; }
  &[rows="80"]  { height:  (1 + 80) * @baseLineHeight; }
  &[rows="85"]  { height:  (1 + 85) * @baseLineHeight; }
  &[rows="90"]  { height:  (1 + 90) * @baseLineHeight; }
  &[rows="95"]  { height:  (1 + 95) * @baseLineHeight; }
  &[rows="100"] { height: (1 + 100) * @baseLineHeight; }
}

.uneditable-textarea {
  .box-sizing(border-box);
}


// SPAN FIX
// --------

.uneditable-input[class*="span"],
.uneditable-textarea[class*="span"],
// Redeclare since the fluid row class is more specific
.row-fluid .uneditable-input[class*="span"],
.row-fluid .uneditable-textarea[class*="span"] {
  float: none;
  margin-left: 0;
}

.input-append .uneditable-input,
.input-prepend .uneditable-input {
  vertical-align: top;
}

// Ensure input-prepend/append never wraps
.input-append .uneditable-input[class*="span"],
.input-prepend .uneditable-input[class*="span"] {
  display: inline-block;
}


// UNEDITABLE FORM
// ---------------

.uneditable-form {
  input[disabled],
  textarea[disabled],
  select[disabled] {
    cursor: auto;
  }
  .uneditable-input,
  .uneditable-textarea {
    cursor: text;
  }
  .form-actions {
    background-color: transparent;
  }
}
