// Layouts
// Fixed-width and fluid (with sidebar) layouts
// --------------------------------------------

// Fluid container for small screens, fixed for big screens
.container-semifluid {
  .container-fixed();
  padding-left: @gridGutterWidth;
  padding-right: @gridGutterWidth;
  max-width: @gridRowWidth;
}
