//
// Labels and badges
// --------------------------------------------------


// Base classes
.label,
.badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: @baseFontSize * .846;
  font-weight: bold;
  line-height: 14px; // ensure proper line-height if floated
  color: @white;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  background-color: @grayLight;
}
// Set unique padding and border-radii
.label {
  .border-radius(3px);
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  .border-radius(9px);
}

// Empty labels/badges collapse
.label,
.badge {
  &:empty {
    display: none;
  }
}

// Hover/focus state, but only for links
a {
  &.label:hover,
  &.label:focus,
  &.badge:hover,
  &.badge:focus {
    color: @white;
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Only give background-color difference to links (and to simplify, we don't qualifty with `a` but [href] attribute)
.label,
.badge {
  // Important (red)
  &-important         { background-color: @errorText; }
  &-important[href]   { background-color: darken(@errorText, 10%); }
  // Warnings (orange)
  &-warning           { background-color: @orange; }
  &-warning[href]     { background-color: darken(@orange, 10%); }
  // Success (green)
  &-success           { background-color: @successText; }
  &-success[href]     { background-color: darken(@successText, 10%); }
  // Info (turquoise)
  &-info              { background-color: @infoText; }
  &-info[href]        { background-color: darken(@infoText, 10%); }
  // Inverse (black)
  &-inverse           { background-color: @grayDark; }
  &-inverse[href]     { background-color: darken(@grayDark, 10%); }
}

// Quick fix for labels/badges in buttons
.btn {
  .label,
  .badge {
    position: relative;
    top: -1px;
  }
}
.btn-mini {
  .label,
  .badge {
    top: 0;
  }
}
