//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: 8px 15px;
  margin: 0 0 @baseLineHeight;
  list-style: none;
  background-color: #f5f5f5;
  .border-radius(@baseBorderRadius);
  > li {
    display: inline-block;
    .ie7-inline-block();
    text-shadow: 0 1px 0 @white;
    > .divider {
      padding: 0 5px;
      color: #ccc;
    }
  }
  > .active {
    color: @grayLight;
  }
}
