//========================================================================
// Emprix extensions to Bootstrap
//========================================================================


.nopadding {
  padding-left: 0px;
}

.clickable {
  cursor: pointer;
}


.no-margin {
  margin: 0px 0px;
}


//------------------------------------------------------------------------
// Very compact buttn styled as link
//------------------------------------------------------------------------
.btn.btn-flat {
    color: @linkColor;
    cursor: pointer;
    text-decoration: none;
    padding: 0px 0px;
    border-color: transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: none;
}

.btn.btn-flat.btn-flat-red {
    color: #FF0000;
}

.btn.btn-flat.btn-flat-red:hover{
    color: #FF0000;
}

.btn.btn-flat:hover{
    color: @linkColorHover;
    background: none;
    text-decoration: none;
}


//------------------------------------------------------------------------
// Table extensions
//------------------------------------------------------------------------
.table.table-no-first-line {

    tr:first-child > th,
    tr:first-child > td,
    tr:first-of-type > th,
    tr:first-of-type > td {
        border-top: none;
    }

}

.table.table-nolines {
    td,
    th {
        border: none;
    }
}

.table.table-nopadding {
    td,
    th {
        padding: 0px;
    }
}

.table.table-valign-middle {
    td,
    th{
        vertical-align: middle;
    }
}

.table {
    td.center,
    th.center
    {
        text-align: center;
    }
}

//------------------------------------------------------------------------
// Wide modal dialog
//------------------------------------------------------------------------
.modal.modal-wide {
  width: 800px;
  margin-left: -400px;
}
