// Jasny.navs-tabbable.less
// Improvements for tabbable component declared in navs.less
// ---------------------------------------------------------


// No outline for tabs and pills
.nav-tabs > li > a,
.nav-pills > li > a {
  outline: none;
}

// Disabled tab
.nav-tabs > li.disabled > a {
  color: #CCCCCC;
  cursor: not-allowed;
}


// TABBABLE
// --------


// COMMON STYLES
// -------------

// Clear any floats
.tabbable {
  .clearfix();
  border-style: solid;
  border-color: #ddd;
  border-width: 0px;
}

// Make the tab content grow correctly
.tabbable > .nav-tabs {
  margin: 0;
}
.tab-content {
  overflow: auto; // prevent content from running below tabs
  border-style: solid;
  border-color: #ddd;
  border-width: 0px;
  padding: 18px 0 0 0;
}

// Tabs on top
.tabbable-bordered {
  .border-radius(4px);
  > .tab-content {
    border-width: 0 1px 1px 1px;
    padding: 20px 20px 10px 20px;
    .border-radius(0 0 4px 4px);
  }
}

// Container as tabbable
body > .container.tabbable > .nav-tabs {
  padding-top: 15px;
}

// BOTTOM
// ------

.tabs-below > .tab-content {
  padding: 0 0 10px 0;
}
.tabs-below.tabbable-bordered {
  > .tab-content {
    border-width: 1px 1px 0 1px;
    padding: 20px 20px 10px 20px;
    .border-radius(4px 4px 0 0);
  }
}
body > .container.tabs-below.tabbable-bodered > .tab-content {
  border-top-width: 0;
  .border-radius(0)
}

// LEFT & RIGHT
// ------------

// Common styles
.tabs-left,
.tabs-right {
  margin-bottom: 20px;
}
.tabs-left > .nav-tabs,
.tabs-right > .nav-tabs {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}
.tabs-left > .tab-content,
.tabs-right > .tab-content {
  overflow: hidden;
}

// Tabs on the left
.tabs-left > .nav-tabs {
  left: 1px;
}
.tabs-left > .nav-tabs > .active > a,
.tabs-left > .nav-tabs > .active > a:hover {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: @white;
}
.tabs-left > .tab-content {
  padding: 0 0 0 19px;
  border-left-width: 1px;
}
.tabs-left.tabbable-bordered {
  border-width: 0 1px 0 0;
  > .tab-content {
    border-width: 1px 0 1px 1px;
    padding: 20px 20px 10px 20px;
    .border-radius(0 4px 4px 0);
  }
}
body > .container.tabs-left.tabbable-bodered > .tab-content {
  border-top-width: 0;
  .border-radius(0 0 4px 0);
}

// Tabs on the right
.tabs-right > .nav-tabs {
  right: 1px;
}
.tabs-right > .nav-tabs > .active > a,
.tabs-right > .nav-tabs > .active > a:hover {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: @white;
}
.tabs-right > .tab-content {
  padding: 0 19px 0 0;
  border-right-width: 1px;
}
.tabs-right.tabbable-bordered {
  border-width: 0 0 0 1px;
  > .tab-content {
    border-width: 1px 1px 1px 0;
    padding: 20px 20px 10px 20px;
    .border-radius(4px 0 0 4px);
  }
}
body > .container.tabs-right.tabbable-bodered > .tab-content {
  border-top-width: 0;
  .border-radius(0 0 0 4px);
}