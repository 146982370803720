// Rowlink.less
// CSS for rowlink plugin
// ----------------------

tr.rowlink td {
  cursor: pointer;
  &.nolink {
    cursor: auto;
  }
}

.table tbody tr.rowlink:hover td {
  background-color: darken(@tableBackgroundHover, 15%);
}

a.rowlink {
  color: inherit;
  font: inherit;
  text-decoration: inherit;
}
