// ICONIC ICONS
// ------------

// Load font
@font-face {
  font-family: IconicStroke;
  font-weight: normal;
  src: url(~'@{iconicFontPath}/iconic_stroke.eot');
  src: local('IconicStroke'), url(~'@{iconicFontPath}/iconic_stroke.eot?#iefix') format('embedded-opentype'), url(~'@{iconicFontPath}/iconic_stroke.woff') format('woff'), url(~'@{iconicFontPath}/iconic_stroke.ttf') format('truetype'), url(~'@{iconicFontPath}/iconic_stroke.svg#iconic') format('svg'), url(~'@{iconicFontPath}/iconic_stroke.otf') format('opentype');
}
@font-face {
  font-family: IconicFill;
  font-weight: normal;
  src: url(~'@{iconicFontPath}/iconic_fill.eot');
  src: local('IconicFill'), url(~'@{iconicFontPath}/iconic_fill.eot?#iefix') format('embedded-opentype'), url(~'@{iconicFontPath}/iconic_fill.woff') format('woff'), url(~'@{iconicFontPath}/iconic_fill.ttf') format('truetype'), url(~'@{iconicFontPath}/iconic_fill.svg#iconic') format('svg'), url(~'@{iconicFontPath}/iconic_fill.otf') format('opentype');
}

// Icons
@media screen, print {
  [class*="iconic-"] {
    font-style: inherit;
    font-weight: normal;
    vertical-align: bottom;
    &:before {
      font-family: IconicFill;
      font-size: 0.9em;
      vertical-align: middle;
      display: inline-block;
      content: "";
      width: 1em;
      text-align: center;
    }
  }
  .iconic-stroke:before {
    font-family: IconicStroke;
  }
  
  .iconic-hash:before                  { content: '\23'; }
  .iconic-question-mark:before         { content: '\3f'; }
  .iconic-at:before                    { content: '\40'; }
  .iconic-pilcrow:before               { content: '\b6'; }
  .iconic-info:before                  { content: '\2139'; }
  .iconic-arrow-left:before            { content: '\2190'; }
  .iconic-arrow-up:before              { content: '\2191'; }
  .iconic-arrow-right:before           { content: '\2192'; }
  .iconic-arrow-down:before            { content: '\2193'; }
  .iconic-home:before                  { content: '\2302'; }
  .iconic-sun:before                   { content: '\2600'; }
  .iconic-cloud:before                 { content: '\2601'; }
  .iconic-umbrella:before              { content: '\2602'; }
  .iconic-star:before                  { content: '\2605'; }
  .iconic-moon:before                  { content: '\263e'; }
  .iconic-heart:before                 { content: '\2764'; }
  .iconic-cog:before                   { content: '\2699'; }
  .iconic-bolt:before                  { content: '\26a1'; }
  .iconic-key:before                   { content: '\26bf'; }
  .iconic-rain:before                  { content: '\26c6'; }
  .iconic-denied:before                { content: '\26d4'; }
  .iconic-mail:before                  { content: '\2709'; }
  .iconic-pen:before                   { content: '\270e'; }
  .iconic-x:before                     { content: '\2717'; }
  .iconic-o-x:before                   { content: '\2718'; }
  .iconic-check:before                 { content: '\2713'; }
  .iconic-o-check:before               { content: '\2714'; }
  .iconic-left-quote:before            { content: '\275d'; }
  .iconic-right-quote:before           { content: '\275e'; }
  .iconic-plus:before                  { content: '\2795'; }
  .iconic-minus:before                 { content: '\2796'; }
  .iconic-curved-arrow:before          { content: '\2935'; }
  .iconic-document-alt:before          { content: '\e000'; }
  .iconic-calendar:before              { content: '\e001'; }
  .iconic-map-pin-alt:before           { content: '\e002'; }
  .iconic-comment-alt1:before          { content: '\e003'; }
  .iconic-comment-alt2:before          { content: '\e004'; }
  .iconic-pen-alt:before               { content: '\e005'; }
  .iconic-pen-alt2:before              { content: '\e006'; }
  .iconic-chat-alt:before              { content: '\e007'; }
  .iconic-o-plus:before                { content: '\e008'; }
  .iconic-o-minus:before               { content: '\e009'; }
  .iconic-bars-alt:before              { content: '\e00a'; }
  .iconic-book-alt:before              { content: '\e00b'; }
  .iconic-aperture-alt:before          { content: '\e00c'; }
  .iconic-beaker-alt:before            { content: '\e010'; }
  .iconic-left-quote-alt:before        { content: '\e011'; }
  .iconic-right-quote-alt:before       { content: '\e012'; }
  .iconic-o-arrow-left:before          { content: '\e013'; }
  .iconic-o-arrow-up:before            { content: '\e014'; }
  .iconic-o-arrow-right:before         { content: '\e015'; }
  .iconic-o-arrow-down:before          { content: '\e016'; }
  .iconic-o-arrow-left-alt:before      { content: '\e017'; }
  .iconic-o-arrow-up-alt:before        { content: '\e018'; }
  .iconic-o-arrow-right-alt:before     { content: '\e019'; }
  .iconic-o-arrow-down-alt:before      { content: '\e01a'; }
  .iconic-brush:before                 { content: '\e01b'; }
  .iconic-brush-alt:before             { content: '\e01c'; }
  .iconic-eyedropper:before            { content: '\e01e'; }
  .iconic-layers:before                { content: '\e01f'; }
  .iconic-layers-alt:before            { content: '\e020'; }
  .iconic-compass:before               { content: '\e021'; }
  .iconic-award:before                 { content: '\e022'; }
  .iconic-beaker:before                { content: '\e023'; }
  .iconic-steering-wheel:before        { content: '\e024'; }
  .iconic-eye:before                   { content: '\e025'; }
  .iconic-aperture:before              { content: '\e026'; }
  .iconic-image:before                 { content: '\e027'; }
  .iconic-chart:before                 { content: '\e028'; }
  .iconic-chart-alt:before             { content: '\e029'; }
  .iconic-target:before                { content: '\e02a'; }
  .iconic-tag:before                   { content: '\e02b'; }
  .iconic-rss:before                   { content: '\e02c'; }
  .iconic-rss-alt:before               { content: '\e02d'; }
  .iconic-share:before                 { content: '\e02e'; }
  .iconic-undo:before                  { content: '\e02f'; }
  .iconic-reload:before                { content: '\e030'; }
  .iconic-reload-alt:before            { content: '\e031'; }
  .iconic-loop:before                  { content: '\e032'; }
  .iconic-loop-alt:before              { content: '\e033'; }
  .iconic-back-forth:before            { content: '\e034'; }
  .iconic-back-forth-alt:before        { content: '\e035'; }
  .iconic-spin:before                  { content: '\e036'; }
  .iconic-spin-alt:before              { content: '\e037'; }
  .iconic-move-horizontal:before       { content: '\e038'; }
  .iconic-move-horizontal-alt:before   { content: '\e039'; }
  .iconic-o-move-horizontal:before     { content: '\e03a'; }
  .iconic-move-vertical:before         { content: '\e03b'; }
  .iconic-move-vertical-alt:before     { content: '\e03c'; }
  .iconic-o-move-vertical:before       { content: '\e03d'; }
  .iconic-move:before                  { content: '\e03e'; }
  .iconic-move-alt:before              { content: '\e03f'; }
  .iconic-o-move:before                { content: '\e040'; }
  .iconic-transfer:before              { content: '\e041'; }
  .iconic-download:before              { content: '\e042'; }
  .iconic-upload:before                { content: '\e043'; }
  .iconic-cloud-download:before        { content: '\e044'; }
  .iconic-cloud-upload:before          { content: '\e045'; }
  .iconic-fork:before                  { content: '\e046'; }
  .iconic-play:before                  { content: '\e047'; }
  .iconic-o-play:before                { content: '\e048'; }
  .iconic-pause:before                 { content: '\e049'; }
  .iconic-stop:before                  { content: '\e04a'; }
  .iconic-eject:before                 { content: '\e04b'; }
  .iconic-first:before                 { content: '\e04c'; }
  .iconic-last:before                  { content: '\e04d'; }
  .iconic-fullscreen:before            { content: '\e04e'; }
  .iconic-fullscreen-alt:before        { content: '\e04f'; }
  .iconic-fullscreen-exit:before       { content: '\e050'; }
  .iconic-fullscreen-exit-alt:before   { content: '\e051'; }
  .iconic-equalizer:before             { content: '\e052'; }
  .iconic-article:before               { content: '\e053'; }
  .iconic-read-more:before             { content: '\e054'; }
  .iconic-list:before                  { content: '\e055'; }
  .iconic-list-nested:before           { content: '\e056'; }
  .iconic-cursor:before                { content: '\e057'; }
  .iconic-dial:before                  { content: '\e058'; }
  .iconic-new-window:before            { content: '\e059'; }
  .iconic-trash:before                 { content: '\e05a'; }
  .iconic-battery-half:before          { content: '\e05b'; }
  .iconic-battery-empty:before         { content: '\e05c'; }
  .iconic-battery-charging:before      { content: '\e05d'; }
  .iconic-chat:before                  { content: '\e05e'; }
  .iconic-mic:before                   { content: '\e05f'; }
  .iconic-movie:before                 { content: '\e060'; }
  .iconic-headphones:before            { content: '\e061'; }
  .iconic-user:before                  { content: '\e062'; }
  .iconic-lightbulb:before             { content: '\e063'; }
  .iconic-cd:before                    { content: '\e064'; }
  .iconic-folder:before                { content: '\e065'; }
  .iconic-document:before              { content: '\e066'; }
  .iconic-pin:before                   { content: '\e067'; }
  .iconic-map-pin:before               { content: '\e068'; }
  .iconic-book:before                  { content: '\e069'; }
  .iconic-book-alt2:before             { content: '\e06a'; }
  .iconic-box:before                   { content: '\e06b'; }
  .iconic-calendar-alt:before          { content: '\e06c'; }
  .iconic-comment:before               { content: '\e06d'; }
  .iconic-iphone:before                { content: '\e06e'; }
  .iconic-bars:before                  { content: '\e06f'; }
  .iconic-camera:before                { content: '\e070'; }
  .iconic-volume-mute:before           { content: '\e071'; }
  .iconic-volume:before                { content: '\e072'; }
  .iconic-battery-full:before          { content: '\e073'; }
  .iconic-magnifying-glass:before      { content: '\e074'; }
  .iconic-lock:before                  { content: '\e075'; }
  .iconic-unlock:before                { content: '\e076'; }
  .iconic-link:before                  { content: '\e077'; }
  .iconic-wrench:before                { content: '\e078'; }
  .iconic-clock:before                 { content: '\e079'; }

  .iconic-sun-stroke:before            { content:'\2600'; font-family: IconicStroke; }
  .iconic-moon-stroke:before           { content:'\263e'; font-family: IconicStroke; }
  .iconic-star-stroke:before           { content:'\2605'; font-family: IconicStroke; }
  .iconic-heart-stroke:before          { content:'\2764'; font-family: IconicStroke; }
  .iconic-key-stroke:before            { content:'\26bf'; font-family: IconicStroke; }
  .iconic-document-alt-stroke:before   { content:'\e000'; font-family: IconicStroke; }
  .iconic-comment-alt1-stroke:before   { content:'\e003'; font-family: IconicStroke; }
  .iconic-comment-alt2-stroke:before   { content:'\e004'; font-family: IconicStroke; }
  .iconic-pen-alt-stroke:before        { content:'\e005'; font-family: IconicStroke; }
  .iconic-chat-alt-stroke:before       { content:'\e007'; font-family: IconicStroke; }
  .iconic-award-stroke:before          { content:'\e022'; font-family: IconicStroke; }
  .iconic-tag-stroke:before            { content:'\e02b'; font-family: IconicStroke; }
  .iconic-trash-stroke:before          { content:'\e05a'; font-family: IconicStroke; }
  .iconic-folder-stroke:before         { content:'\e065'; font-family: IconicStroke; }
  .iconic-document-stroke:before       { content:'\e066'; font-family: IconicStroke; }
  .iconic-map-pin-stroke:before        { content:'\e068'; font-family: IconicStroke; }
  .iconic-calendar-alt-stroke:before   { content:'\e06c'; font-family: IconicStroke; }
  .iconic-comment-stroke:before        { content:'\e06d'; font-family: IconicStroke; }
  .iconic-lock-stroke:before           { content:'\e075'; font-family: IconicStroke; }
  .iconic-unlock-stroke:before         { content:'\e076'; font-family: IconicStroke; }
 }
