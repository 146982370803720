// Forms.less
// General improvements on form styling
// ------------------------------------


// GENERAL STYLES
// --------------

form {
  > *:last-child {
    margin-bottom: 0;
  }
}

label input[type="image"],
label input[type="checkbox"],
label input[type="radio"] {
  vertical-align: middle;
}

select {
  padding-left: 2px;
}

// Smaller labels
// --------------
.small-labels {
  .control-group > label {
    width: 70px;
  }
  .controls {
    margin-left: 80px;
  }
  .form-actions {
    padding-left: 80px;
  }
}


// Combining horizonal and vertical forms
// --------------------------------------

.form-vertical .form-horizontal {
  .control-group > label {
    text-align: left;
  }
}
.form-horizontal .form-vertical {
  .control-group > label {
    float: none;
    padding-top: 0;
    text-align: left;
  }
  .controls {
    margin-left: 0;
  }
  &.form-actions, .form-actions {
    padding-left: 20px;
  }
}

.control-group .control-group {
  margin-bottom: 0;
}


// Wells in forms
// --------------

.form-horizontal .well {
  .control-label {
    width: 120px;
  }
  .controls {
    margin-left: 140px;
  }
}

form .well > *:last-child {
  margin-bottom: 0;
}


// CSS for editor textarea
// -----------------------

.editor {
  .box-sizing(border-box);
  height: 100px;
  width: 100%;
  padding: 5px;
}
.uneditable-textarea.editor-html {
  white-space: normal;
  padding: 5px 3px 5px 5px;
}
textarea.editor-html {
  visibility: hidden; // Assume it will be replaced
}
