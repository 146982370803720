//
// Layouts
// --------------------------------------------------


// Container (centered, fixed-width layouts)
.container {
  .container-fixed();
}

// Fluid layouts (left aligned, with sidebar, min- & max-width content)
.container-fluid {
  padding-right: @gridGutterWidth;
  padding-left: @gridGutterWidth;
  .clearfix();
}